<template>
  <div>
    <div class="menu-page">
      <the-breadcrumb class="sign-page-top-1"></the-breadcrumb>
      <div class="content">
        <div class="left">
          <fm-tree v-loadingx="loading" :nodes="nodes" search is-selected @selected="onSelected" :contextmenu="['新增', '删除', 'API']" @contextmenu="onContextmenu"></fm-tree>
        </div>
        <div class="right">
          <FmForm label-width="120px">
            <FmFormItem label="菜单名">
              <FmInput v-model="chooseData.name" required v-verifier @verifier="(msg) => $set(verifier, 'name',  msg)" placeholder="菜单名"></FmInput>
            </FmFormItem>
            <FmFormItem label="图标">
              <FmInput v-model="chooseData.icon" required v-verifier @verifier="(msg) => $set(verifier, 'icon',  msg)" placeholder="图标"></FmInput>
            </FmFormItem>
            <FmFormItem label="类型">
              <FmSelect v-model="chooseData.type" required v-verifier @verifier="(msg) => $set(verifier, 'type',  msg)" placeholder="类型" block>
                <FmOption v-for="menu in menuTypes" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem label="地址">
              <FmInput v-model="chooseData.url" v-verifier @verifier="(msg) => $set(verifier, 'url',  msg)" placeholder="地址"></FmInput>
            </FmFormItem>
            <FmFormItem label="排序">
              <div class="number-input">
                <span class="up" @click="setSort(-1)">上调</span>
                <input :value="chooseData.sort !== undefined && chooseData.sort !== null ? chooseData.sort : sortMax" @blur="sortChange"/>
                <span class="down" @click="setSort(+1)">下调</span>
              </div>
            </FmFormItem>
            <FmFormItem label="父级菜单">
              <FmSelect v-verifier @verifier="(msg) => $set(verifier, 'pid',  msg)" v-model="chooseData.pid" placeholder="父级菜单" block>
                <FmOption v-for="menu in sysMenus" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem label="客户端类型">
              <FmSelect required v-verifier @verifier="(msg) => $set(verifier, 'clientType',  msg)" v-model="chooseData.clientType" placeholder="客户端类型" block>
                <FmOption v-for="menu in clientTypes" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem>
              <fm-btn :disabled="!verifierPass" @click="formSubmit(chooseData)">{{chooseData.id ? '确定修改' : '新增菜单'}}</fm-btn>
            </FmFormItem>
          </FmForm>
        </div>
      </div>
    </div>
    <fm-modal
      :title="chooseDataApi ? chooseDataApi.name : ''"
      :value="openDialogApi"
      width="90%"
      v-if="openDialogApi"
      theme="mh-withe"
      @cancel="openDialogApi = false">
      <div class="modal-c">
        <div class="api-item">
          <fm-title title-text="无权限API">
          </fm-title>
          <div class="t-c">
            <fm-table
              :column-list="columnList"
              :data-list="notChooseApis"
              @tableAction="tableAction"
              :table-actions="tableAction1"
              :show-search="true">
            </fm-table>
          </div>
        </div>
        <div class="api-item">
          <fm-title title-text="有权限API" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
          </fm-title>
          <div class="t-c">
            <fm-table
              :column-list="columnList"
              :data-list="chooseApis"
              @tableAction="tableAction"
              :table-actions="tableAction2"
              :show-search="true">
            </fm-table>
          </div>
        </div>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import TheBreadcrumb from '@/components/base/TheBreadcrumb'

import {
  menuRequest as request,
  systemApisGet,
  menuApisUpate
} from '@/api'

import {
  tools
} from '@/fmlib'

export default {
  components: {
    TheBreadcrumb
  },
  computed: {
    verifierPass () {
      return Object.keys(this.verifier).find(v => this.verifier[v] !== '') === undefined
    },
    titleMenus: {
      get () {
        return [{
          key: 'save',
          label: '保存'
        }]
      }
    },
    menuTypes: {
      get () {
        return this.$store.getters.menuTypeList
      }
    },
    sysMenus: {
      get () {
        return [{label: '无', key: 0}, ...this.$store.getters.menuList]
      }
    },
    clientTypes: {
      get () {
        return this.$store.getters.clientTypeList
      }
    },
    columnList: {
      get () {
        return [{
          field: 'name',
          title: 'API名称'
        },
        {
          field: 'path',
          title: '路径'
        },
        {
          field: 'method',
          title: '类型'
        },
        {
          field: 'serviceName',
          title: '服务名称'
        },
        {
          field: 'isBaseText',
          title: '基础API',
          searchType: 'select',
          searchSelectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}]
        }]
      }
    },
    nodes () {
      let fn = (menu) => {
        return {
          title: menu.name,
          data: menu
        }
      }
      let result = this.dataList.filter(v => v.pid === 0).map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = this.dataList.filter(v => v.pid === node.data.id).map(fn)
          each(node.children)
        })
      }
      each(result)

      return result
    },
    sorts () {
      return this.dataList.map(v => ~~v.sort)
    },
    sortMax () {
      return Math.max(...this.sorts)
    },
    sortMin () {
      return Math.min(...this.sorts)
    }
  },
  created () {
    this.loadData()
    this.loadApis()
  },
  methods: {
    tableAction (data) {
      let dataItem = data.data
      if (data.action === 'add') {
        this.chooseApis.push(dataItem)
        let notChooseApis = []
        this.notChooseApis.forEach((item) => {
          if (item.id !== dataItem.id) {
            notChooseApis.push(item)
          }
        })
        this.notChooseApis = notChooseApis
      } else if (data.action === 'del') {
        this.notChooseApis.push(dataItem)
        let chooseApis = []
        this.chooseApis.forEach((item) => {
          if (item.id !== dataItem.id) {
            chooseApis.push(item)
          }
        })
        this.chooseApis = chooseApis
      }
    },
    onContextmenu (menu, data) {
      if (menu === '新增') {
        this.chooseData = {
          pid: data.data.data.id
        }
      } else if (menu === '删除') {
        this.delData(data.data.data)
      } else if (menu === 'API') {
        this.chooseDataApi = data.data.data
        this.openDialogApi = true
        let chooseApis = []
        let notChooseApis = []
        let hisApiIds = []
        this.chooseDataApi.apis.forEach((item) => {
          hisApiIds.push(item.id)
        })
        this.apis.forEach((item) => {
          if (hisApiIds.includes(item.id)) {
            chooseApis.push(item)
          } else {
            notChooseApis.push(item)
          }
        })
        this.chooseApis = chooseApis
        this.notChooseApis = notChooseApis
      }
    },
    onSelected (menu) {
      this.$nextTick(() => {
        this.chooseData = menu.data.data
      })
    },
    async delData (data) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除菜单 ' + data.name + ' 吗?'})
      if (result) {
        this.btn_status.del = true
        request.del(data.id).then(() => {
          this.btn_status.del = false
          this.$notice.info({
            title: '系统提示',
            desc: '菜单已删除'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data) {
      if (data.id && data.pid && data.pid === data.id) {
        this.$notice.warning({
          title: '系统提示',
          desc: '父级菜单不能选自己。'
        })
        return
      }
      // 当菜单无排序时将菜单调至最后
      if (data.sort === undefined || data.sort === null) {
        data.sort = this.sortMax + 1
      }
      if (this.chooseData && this.chooseData.id) {
        request.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '菜单修改完成'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        request.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '菜单新增完成'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu () {
      let apiIds = []
      this.chooseApis.forEach((item) => {
        apiIds.push(item.id)
      })
      menuApisUpate(this.chooseDataApi.id, {apiIds}).then(() => {
        this.$notice.success({
          title: '系统提示',
          desc: '菜单接口修改完成'
        })
        this.loadData()
      })
    },
    loadData () {
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        let newData = tools.treeToList(data, 'children')
        this.$store.dispatch('setMenuList', newData)
        this.dataList = newData
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    loadApis () {
      systemApisGet().then((data) => {
        data.forEach((item) => {
          item.isBaseText = item.isBase === 0 ? '否' : '是'
        })
        this.apis = data
      })
    },
    setSort (opr, t = false) {
      if (this.chooseData) {
        const sort = t ? opr : (this.chooseData.sort === null || this.chooseData.sort === undefined ?  this.sortMax : this.chooseData.sort) + opr
        if (sort >= 0 && sort >= this.sortMin - 1 && sort <= this.sortMax + 1) {
          this.$set(this.chooseData, 'sort', sort)
        } else {
          this.$notice.warning('无效的排序取值范围')
          this.$set(this.chooseData, 'sort', null)
        }
      }
    },
    sortChange ({target}) {
      let sort = parseInt(target.value)
      if (isNaN(sort) || this.chooseData.sort === undefined || this.chooseData.sort === null) {
        this.$set(this.chooseData, 'sort', this.sortMax)
      } else {
        this.setSort(sort, true)
      }
    }
  },
  data () {
    return {
      verifier: {},
      tableAction1: [{
        label: '添加',
        key: 'add'
      }],
      tableAction2: [{
        label: '删除',
        key: 'del'
      }],
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: {},
      chooseDataApi: null,
      notChooseApis: [],
      chooseApis: [],
      openDialog: false,
      btn_status: {
        del: false
      },
      apis: [],
      openDialogApi: false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/values.less';
.modal-c {
  height: 30rem;
  width: 100%;
  display: flex;
  .t-c {
    flex: 1;
  }
  .api-item {
    margin: 0 1rem;
    border: @size-border solid @color-border;
    border-radius: @size-border-radius;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
  .menu-page {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 0 1rem;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
  }
  .sign-page-top-1 {
    margin-bottom: 1rem;
  }
  .content {
    .left, .right {
      background-color: #FFF;
      border-radius: 5px;
      padding: 10px;
    }
    display: flex;
    .left {
      width: 20%;
      min-width: 20%;
      height: 80vh;
      overflow-y: auto;
      margin-right: 0.5rem;
    }
    .right {
      flex-grow: 1;
    }
  }
  .number-input {
    border: 1px solid #dcdddd;
    border-radius: 4px;
    display: flex;
    &, input, span {
      line-height: 1.9rem;
      font-size: 1rem;
      color: #515a6e;
    }
    input, span {
      display: inline-block;
      flex: 1;
      border: none;
      text-align: center;
    }
    span {
      &:first-of-type, &:last-of-type {
        background-color: #dcdddd;
        width: 20%;
        cursor: pointer;
      }
    }
  }
</style>